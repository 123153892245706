export enum SystemFiles {
    LOGO_LIGHT_BG = "logo-light-bg.png",
    LOGO_DARK_BG = "logo-dark-bg.png",
    FONT_LIGHT = "light-font.woff2",
    FONT_REGULAR = "regular-font.woff2",
    FONT_BOLD = "bold-font.woff2",
}

export enum Colors {
    PRIMARY = "primary",
    SECUNDARY = "secondary",
    TERTIARY = "tertiary",
}

export enum Fonts {
    LIGHT = "light",
    REGULAR = "regular",
    BOLD = "bold",
}

export enum TierType {
    EXPLORE = "EXPLORE",
    EXPLORE_PLUS = "EXPLORE_PLUS",
    ELITE = "ELITE",
    ENTERPRISE = "ENTERPRISE"
}

export enum VerticalType {
    NONE = "NONE",
    GENERAL = "GENERAL",
    SALES_MARKETING = "SALES_MARKETING",
    TECHNOLOGY = "TECHNOLOGY",
    FOOD_BEVERAGES = "FOOD_BEVERAGES",
    REAL_ESTATE = "REAL_ESTATE",
}

export const FONT_NAME_PREFIX = "audience-";

export interface ITierConfig {
    tierType: TierType;

    maxNrOfTeams: number;
    hasUsageInsights: boolean;
    hasContentInsights: boolean;
    hasQuickLaunch: boolean;
    hasGuests: boolean;

    hasPresentations: boolean;
    hasContentApps: boolean;
    availableApps: string[];
    availableCrms: string[];

    maxStorage: number;
    maxFileSize: number;

    hasBrandedPwa: boolean;
    hasBrandedPortal: boolean;
    hasBrandedEmails: boolean;

    hasPortalSecurity:boolean;

    minNrOfUsers: number;
    userPackAmount: number;
    pricePerUserPerMonthOn12MonthTerm: number;
    pricePerUserPerMonthOn36MonthTerm: number;
}

export interface ITierQuota {
    nrOfUsers: number;
    nrOfTeams: number;
}
