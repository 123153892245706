import {
    IAudienceBodyDto,
    IAudienceConfigDto,
    IAudienceFileDto,
    IAudienceMetaDto,
    IGuestDto
} from "@/audience/_model/audience.dto";
import {IEntityAudienceShareOutlineDto} from "@/entity/_model/entity.dto";
import EntityModel from "@/entity/_model/EntityModel";
import {IModelDto} from "@/__libs/_model/model.dto";
import {EntityType} from "@/entity/_model/entity.constants";
import {DtoType} from "@/_model/app.constants";
import {IAudienceActivityDto} from "@/audience/track/_model/audience_activity.dto";
import DataProviderModel from "@/data_tool/data_provider/_model/DataProviderModel";
import {IDataProviderBodyDto} from "@/data_tool/_model/data_tool.dto";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {PortalAuthType} from "@/audience/_model/audience.constants";

export default class AudienceModel extends EntityModel implements IAudienceBodyDto, IAudienceMetaDto, IAudienceConfigDto
{
    public entityType:EntityType = EntityType.AUDIENCE;


    //body
    public displayName:string = "";
    public email:string = "";
    public langCode:string = "en";
    public firstName:string = "";
    public lastName:string = "";
    public companyID:string = "";
    public avatarFileUri:string = `assetFolder://asf-${AppUserModel.getInstance().project.identifier}-system-assets/audience_avatar_default.png`;
    public referenceCode:string = "";
    public apps:any = {};
    public splashFileUri?:string;

    //custom properties
    public dataSchemaURI:string = "dataSchema://audience_schema"; //can in the future be used to create or change to a different "type" of audience with different custom props
    public data:any = {};
    public dataDtp?:DataProviderModel;
    public funnelStage:string = "";

    //meta
    public importedFrom:string = "";
    public importedIdentifier:string = "";
    public portalToken:string = "";
    public portalShortLinkID:string = "";
    public isPortalEnabled:boolean = true;
    public portalAuthType:PortalAuthType = PortalAuthType.LINK_ONLY;
    public portalSecret:string = "";
    public portalEnabledSince:Date = new Date();
    public consentDate:Date | null = null;

    //config (the configuration between the appUser and this audience)
    public notifyPortalVisit:boolean = false;

    //content share
    public contentShares:IEntityAudienceShareOutlineDto[] = []; // | IAudienceFileDto[] ????
    public latestSharesRetrieval:Date | null = null;
    public areSharesStale:boolean = true; //are the shares retrieved online or do they come from a previous fetch

    //track
    public activities:IAudienceActivityDto[] = [];
    public latestActivitiesRetrieval:Date | null = null;
    public areActivitiesStale:boolean = true; //are the activities retrieved online or do they come from a previous fetch

    //files
    public files:IAudienceFileDto[] = [];

    //guests
    public guests:IGuestDto[] = [];

    //---------------------------------
    // Constructor
    //---------------------------------

    constructor(p_dto?:IModelDto)
    {
        super();
        if (p_dto)
        {
            this.mapFromDto(p_dto);
        }
    }

    //---------------------------------
    // Public Methods
    //---------------------------------

    public mapFromDto(p_dto:IModelDto)
    {
        super.mapFromDto(p_dto);
        this.dataDtp = new DataProviderModel({data: this.data, dataSchemaURI: this.dataSchemaURI, ID: this.ID}, this);
    }


    //untill typescript has proper reflection, we will have to do it this way:
    public mapToDto(p_dtoType:DtoType):IModelDto
    {
        if (p_dtoType === DtoType.BODY)
        {
            const dto:IAudienceBodyDto = {
                ID           : this.ID,
                version      : this.version,
                displayName  : this.displayName,
                email        : this.email,
                langCode     : this.langCode,
                firstName    : this.firstName,
                lastName     : this.lastName,
                companyID    : this.companyID,
                avatarFileUri: this.avatarFileUri,
                referenceCode: this.referenceCode,
                apps         : this.apps,
                dataSchemaURI: this.dataSchemaURI,
                funnelStage  : this.funnelStage
            };
            if (this.dataDtp)
            {
                dto.data = (this.dataDtp.mapToDto(DtoType.BODY) as IDataProviderBodyDto).data;
            }
            if (this.splashFileUri)
            {
                dto.splashFileUri = this.splashFileUri;
            }
            return dto;
        }
        else if (p_dtoType === DtoType.CONFIG)
        {
            const dto:IAudienceConfigDto = {
                notifyPortalVisit: this.notifyPortalVisit
            };
            return dto;
        }
        else
        {
            return super.mapToDto(p_dtoType);
        }
    }

}
