import {
    IDataItemDefinitionDto,
    IDataProviderBodyDto,
    IDataProviderMetaDto,
    IDataSchemaDto, IXlsImportWorkbookResult, IXlsImportResult, IXlsSheet
} from "@/data_tool/_model/data_tool.dto";
import EntityModel from "@/entity/_model/EntityModel";
import {IModelDto} from "@/__libs/_model/model.dto";
import {EntityType, LoadingStatus} from "@/entity/_model/entity.constants";
import {DtoType} from "@/_model/app.constants";
import {IMultiLangString, LanguageManager} from "@/__libs/language_manager/LanguageManager";
import DataProviderEntryModel from "@/data_tool/data_provider/_model/DataProviderEntryModel";
import Dictionary from "@/__libs/_model/Dictionary";
import DataItemListModel from "@/data_tool/data_item/_model/DataItemListModel";
import {RightAction} from "@/team/_model/role.constants";
import DataProviderListModel from "@/data_tool/data_provider/_model/DataProviderListModel";
import DataProviderSelectionState from "@/data_tool/data_provider/_model/DataProviderSelectionState";
import {DataProviderViewMode} from "@/data_tool/_model/data_tool.constants";
import DataUtil from "@/data_tool/_controller/DataUtil";

export default class DataProviderModel extends EntityModel implements IDataProviderBodyDto, IDataProviderMetaDto
{
    public entityType:EntityType = EntityType.DATA_PROVIDER;


    //body
    public name:IMultiLangString = {"en": ""};
    public dataSchemaURI:string = "dataSchema://";
    public data:any = {};
    public icon:string = "far fa-sticky-note";

    //
    public dataSchemaDto!:IDataSchemaDto;
    public dtpEntries:DataProviderEntryModel[] = [];

    public isMultilingual:boolean = false; //is only set to true if one of the (editable) properties is multilingual
    public activeLangCode:string = "en";

    // a dictionary of available schema's with their identifier as key. Used to avoid needless reloading if a schema is already loaded for eg another property
    public dataSchemaDict:Dictionary<IDataSchemaDto, string> = new Dictionary<IDataSchemaDto, string>();

    // a dictionary of available data lists with their identifier (dataProviderID#itemDefinitionIdentifier) as key.
    // Used to avoid needless reloading if a list is already loaded for eg another property
    public dataItemListsDict:Dictionary<DataItemListModel, string> = new Dictionary<DataItemListModel, string>();



    //---------------------------------
    // Constructor
    //---------------------------------

    constructor(p_dto?:IModelDto, p_parentEntity?:EntityModel)
    {
        super(p_parentEntity);
        if (p_dto)
        {
            this.mapFromDto(p_dto);
        }
    }

    //---------------------------------
    // Public Methods
    //---------------------------------

    public mapFromDto(p_dto:IModelDto)
    {
        super.mapFromDto(p_dto);

        //the body has loaded: check if this should be the selected one in picker mode
        const selState:DataProviderSelectionState = DataProviderListModel.getInstance().pickerSelState;
        if (this.loadingStatus > LoadingStatus.META_LOADED && selState.viewMode === DataProviderViewMode.PICKER && selState.selectedDtpUri && !selState.selected)
        {
            const dtpID:string | null = selState.selectedDtpUri.split("/")[2];
            if (dtpID === this.ID)
            {
                selState.selected = this;
            }
        }
    }

    // until typescript has proper reflection, we will have to do it this way:
    public mapToDto(p_dtoType:DtoType):IModelDto
    {

        if (p_dtoType === DtoType.BODY)
        {
            let data:any = {};
            //if there are no entries yet, the data has not been built into actual models, so just return the raw data (eg when dtp is copied)
            if (this.dtpEntries.length === 0)
            {
                data = this.data;
            }
            else
            {
                //get the dto's for every data entry
                for (let i = 0; i < this.dtpEntries.length; i++)
                {
                    const dtpEntry = this.dtpEntries[i];
                    data[dtpEntry.itemDefinition.identifier] = dtpEntry.mapToDto(DtoType.BODY);
                }
                this.data = data;
            }

            // console.log(JSON.stringify(data, null, 3));

            const dto:IDataProviderBodyDto = {
                ID           : this.ID,
                version      : this.version,
                name         : this.name,
                dataSchemaURI: this.dataSchemaURI,
                icon         : this.icon,
                data         : data
            };
            if(this.identifier)
            {
                dto.identifier = this.identifier;
            }
            return dto;
        }
        else
        {
            return super.mapToDto(p_dtoType);
        }
    }

    //build the actual dataprovider from the schema: combine raw data with definitions into models
    public build(p_schema:IDataSchemaDto)
    {
        this.dataSchemaDto = p_schema;

        this.icon = this.dataSchemaDto.icon;

        this.dtpEntries = [];

        for (let i = 0; i < this.dataSchemaDto.itemDefinitions.length; i++)
        {
            const itemDefinition:IDataItemDefinitionDto = this.dataSchemaDto.itemDefinitions[i];
            if (itemDefinition.hasDataProviderEntry)
            {
                const entryData:any = this.data[itemDefinition.identifier];
                const dpEntry:DataProviderEntryModel = new DataProviderEntryModel(itemDefinition, entryData, this, null);
                this.dtpEntries.push(dpEntry);
            }
        }

    }

    public getItemDefinitionByURI(p_itemDefinitionURI:string):IDataItemDefinitionDto | null
    {
        const dataSchemaURI:string = p_itemDefinitionURI.split("#")[0];
        const itemIdentifier:string = p_itemDefinitionURI.split("#")[1];

        let schema:IDataSchemaDto | null = this.dataSchemaDict.getItem(dataSchemaURI);

        if (schema)
        {
            for (let i = 0; i < schema.itemDefinitions.length; i++)
            {
                const itemDefinition:IDataItemDefinitionDto = schema.itemDefinitions[i];
                if (itemDefinition.identifier === itemIdentifier)
                {
                    return itemDefinition;
                }
            }
        }

        return null;
    }

    public getDataProviderURIifInTheSameSchema(p_itemDefinitionURI:string):string | null
    {
        //check if the p_itemDefinitionURI points to the same schema (the schema this dataprovider is made from)
        if(p_itemDefinitionURI)
        {
            const uri = DataUtil.resolveURI(p_itemDefinitionURI);
            if(uri.path === this.dataSchemaDto.identifier){
                return "dataProvider://" + this.ID;
            }
        }
        return null;
    }

    public renameAsCopy()
    {
        LanguageManager.renameAsCopy(this.name);
    }

    public async exportToXls():Promise<IXlsSheet[]>
    {
        const sheets:IXlsSheet[] = [];
        for (let i = 0; i < this.dtpEntries.length; i++)
        {
            const sheet:IXlsSheet = await this.dtpEntries[i].exportToSheet();
            sheets.push(sheet);
        }
        return sheets;
    }


    public async importFromXls(p_sheets:IXlsSheet[]):Promise<IXlsImportWorkbookResult>
    {
        const result:IXlsImportWorkbookResult = {isSuccessful: true, parseMessages: [], sheetResults:[]};



        //todo: do a "mapToRows" run where you convert the array format into a dto (data)objects format, which we can then "mapFromDto"

        const data:any = {};

        for (let i = 0; i < this.dataSchemaDto.itemDefinitions.length; i++)
        {
            const itemDefinition:IDataItemDefinitionDto = this.dataSchemaDto.itemDefinitions[i];
            if (itemDefinition.hasDataProviderEntry)
            {
                const sheet:IXlsSheet | undefined = p_sheets.find(sheet => {
                    return sheet.identifier === itemDefinition.identifier
                });
                if(sheet){
                    const dtpEntryForMapping:DataProviderEntryModel = new DataProviderEntryModel(itemDefinition, null, this, null); //todo convert to static
                    const sheetResult:IXlsImportResult = dtpEntryForMapping.importFromSheet(this, sheet, data);
                    result.sheetResults.push(sheetResult);
                    if(!sheetResult.isSuccessful)
                    {
                        result.isSuccessful = false;
                        result.fatalMessage = {type:"SHEET_FATAL"};
                        // return result;
                    }

                }else{
                    // console.log(itemDefinition.identifier, "not found");
                    result.isSuccessful = false;
                    result.fatalMessage = {type:"SHEET_NOT_FOUND"};
                    // return result;
                }
            }
        }

        this.data = data;
        console.log(this.data);
        this.build(this.dataSchemaDto );

        return result;
    }



}
