import {
    IDataItemDefinitionDto,
    IPropertyDefinitionDto,
    IXlsColumn,
    IXlsImportResult
} from "@/data_tool/_model/data_tool.dto";
import PropertyModel from "@/data_tool/data_item/_model/PropertyModel";
import {DataModel} from "@/data_tool/_model/DataModel";
import DataProviderModel from "@/data_tool/data_provider/_model/DataProviderModel";
import {DtoType} from "@/_model/app.constants";
import DataItemModel from "@/data_tool/data_item/_model/DataItemModel";
import DataUtil from "@/data_tool/_controller/DataUtil";
import {InValidType, PropertyType} from "@/data_tool/_model/data_tool.constants";

export default class DataItemSelectListModel extends DataModel
{



    public propertyDefinition:IPropertyDefinitionDto;

    public itemDefinition:IDataItemDefinitionDto; //the definition for all the items in this list

    private _selection:string | string[] | null = null; //an __id or an array of __ids
    get selection():string | string[] | null
    {
        return this._selection;
    }

    set selection(value:string | string[] | null)
    {
        // console.log("set selection:", value);
        this._selection = value;
        if (this.__parent)
        {
            this.__parent.validate();
        }
        this.dataProvider.hasChanges = true;
    }


    private _dataProviderURI:string | null = null; // the dataprovider used to fill the list
    get dataProviderURI():string | null
    {
        return this._dataProviderURI;
    }

    set dataProviderURI(value:string | null)
    {
        if (value !== this._dataProviderURI)
        {
            this._dataProviderURI = value;
            this.selection = this.propertyDefinition.isMultipleSelect ? [] : null; //if the dataprovider changes, the select should be destroyed also, because it is no longer valid with the new dtp
            this.dataProvider.hasChanges = true;
        }
    }


    //---------------------------------
    // Constructor
    //---------------------------------

    constructor(p_itemDefinition:IDataItemDefinitionDto, p_propertyDefinition:IPropertyDefinitionDto, p_data:any, p_dataProvider:DataProviderModel, p_parent:DataModel | null)
    {
        super(p_dataProvider, p_parent);
        this.itemDefinition = p_itemDefinition;
        this.propertyDefinition = p_propertyDefinition;
        this.build(p_data);
    }

    //---------------------------------
    // Public Methods
    //---------------------------------

    public build(p_data:any)
    {
        if (p_data)
        {
            if (p_data.dataProviderURI)
            {
                this._dataProviderURI = p_data.dataProviderURI;
            }
            if (p_data.selection)
            {
                this._selection = DataUtil.deepCopy(p_data.selection); //these value(s) are bound with the select form element
            }
        }
        if(!this._dataProviderURI)
        {
            // no list (dataProviderURI) chosen yet, is there a possibility to link to myself as a default?
            this._dataProviderURI = this.dataProvider.getDataProviderURIifInTheSameSchema(this.propertyDefinition.itemDefinitionURI!);
        }

        if (!this._selection && this.propertyDefinition.isMultipleSelect)
        {
            this._selection = [];
        }
    }


    public mapToDto(p_dtoType:DtoType):any
    {
        if (p_dtoType === DtoType.BODY)
        {
            return {
                dataProviderURI: this._dataProviderURI,
                selection      : this._selection
            }
        }
    }


    public checkValue():InValidType | null
    {

        if (this.propertyDefinition.isMultipleSelect)
        {
            if (this.selection!.length < this.propertyDefinition.minItems!)
            {
                return InValidType.MAKE_MIN_SELECTION;
            }
            if (this.selection!.length > this.propertyDefinition.maxItems!)
            {
                return InValidType.MAKE_MAX_SELECTION;
            }
        }
        else
        {
            if (!this.selection)
            {
                return InValidType.MAKE_A_SELECTION;
            }
        }



        return null;
    }


    public mapToRows(p_targetRow:any, p_columnPrefix:string)
    {
        p_targetRow[`${p_columnPrefix}:chosen list`] = this._dataProviderURI;
        if (this._selection)
        {
            p_targetRow[`${p_columnPrefix}`] = this.propertyDefinition.isMultipleSelect ? (this._selection as string[]).join(",") : this._selection;
        }
    }

    public static mapFromRow(p_propDefinition:IPropertyDefinitionDto, p_row:any, p_columns:IXlsColumn[], p_result:IXlsImportResult)
    {
        const dto:any = {};
        const dataProviderURIColumn:IXlsColumn = p_columns[0]; //todo: safe to presume this is the first, or look for ":chosen list in column", or give it type when building columns?
        const selectionColumn:IXlsColumn = p_columns[1];

        dto.dataProviderURI = p_row[dataProviderURIColumn.identifier]; // todo validate

        const selectionValue = p_row[selectionColumn.identifier];
        if (selectionValue)
        {
            if (p_propDefinition.isMultipleSelect)
            {
                dto.selection = selectionValue.split(",");
            }
            else
            {
                dto.selection = selectionValue;
            }
        }


        return dto;
    }

}
