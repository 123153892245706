import {
    IDataItemDefinitionDto,
    IDataProviderBodyDto,
    IPropertyDefinitionDto,
    IXlsColumn, IXlsImportResult
} from "@/data_tool/_model/data_tool.dto";
import {InValidType, PropertyType, StringDataFormat} from "@/data_tool/_model/data_tool.constants";
import languageManager, {IMultiLangString} from "@/__libs/language_manager/LanguageManager";
import {DataModel} from "@/data_tool/_model/DataModel";
import DataProviderModel from "@/data_tool/data_provider/_model/DataProviderModel";
import {DtoType} from "@/_model/app.constants";
import DataUtil from "@/data_tool/_controller/DataUtil";
import DataItemModel from "@/data_tool/data_item/_model/DataItemModel";
import DataItemListModel from "@/data_tool/data_item/_model/DataItemListModel";
import DataItemSelectListModel from "@/data_tool/data_item/_model/DataItemSelectListModel";
import AppUserModel from "@/project/user/_model/AppUserModel";
import DataItemDictionaryModel from "@/data_tool/data_item/_model/DataItemDictionaryModel";
import Util from "@/__libs/utility/Util";
import DataItemProviderLinkModel from "@/data_tool/data_item/_model/DataItemProviderLinkModel";
import DataItemContentLinkModel from "@/data_tool/data_item/_model/DataItemContentLinkModel";
import DataItemExternalListModel from "@/data_tool/data_item/_model/DataItemExternalListModel";

export default class PropertyModel extends DataModel
{

    //public name:string = "";

    public propertyDefinition:IPropertyDefinitionDto;

    //meta
    public isEditable:boolean = false; //dependent of rights (your own dtp , shared editable dtp or shared translatable and allowed active langCode)

    public inValidType:InValidType | null = null;  //if invalid, this is the type
    public inValidLanguages:string[] = [];  //if invalid and multilingual, these are the invalid languages

    public shownFallBackLangCode:string | null = null;


    public rawValue:any;
    private _displayedValue:any; //the one bound with the Property.vue component
    get displayedValue():any
    {
        // consdole.log("getting", this.propertyDefinition.identifier);
        if (this.propertyDefinition.isMultiLingual) //only possible when primitive
        {
            const multiLangChosenValue = languageManager.getChosenTranslationForValue(this._displayedValue, this.dataProvider.activeLangCode);
            if (multiLangChosenValue.langCode !== this.dataProvider.activeLangCode)
            {
                this.shownFallBackLangCode = multiLangChosenValue.langCode;
            }
            else
            {
                this.shownFallBackLangCode = null;
            }
            return multiLangChosenValue.translation;
        }
        else
        {
            return this._displayedValue;  //this can be a primitive value or a pointer to a container of a non-primitive one
        }
    }

    set displayedValue(value:any) //called by vue component when type is primitive
    {
        // console.log(this.logModelPath(), JSON.stringify(value));
        let hasChanges:boolean = false;
        if (this.propertyDefinition.isMultiLingual)
        {
            if (this._displayedValue[this.dataProvider.activeLangCode] === undefined)
            {
                this.shownFallBackLangCode = null;
            }
            // console.log("setting", this.propertyDefinition.identifier, this.dataProvider.activeLangCode, "to", value);
            if (this._displayedValue[this.dataProvider.activeLangCode] !== value)
            {
                hasChanges = true;
            }
            this._displayedValue[this.dataProvider.activeLangCode] = value;

        }
        else
        {
            // console.log("setting", this.propertyDefinition.identifier, "to", value);
            if (this._displayedValue !== value)
            {
                hasChanges = true;
            }
            this._displayedValue = value;
        }


        //check if valid,
        this.validate();

        if (this.isValid)
        {
            //and if so set rawValue
            this.rawValue = DataUtil.deepCopy(this._displayedValue); // because need clone of data when isMultiLingual
            //set delta
            if (hasChanges)
            {
                this.dataProvider.hasChanges = true;
            }
        }



    }

    //=========  type specific

    //SINGLE_ITEM, ITEM_LIST, ITEM_DICTIONARY, SELECT_LIST
    public itemDefinition?:IDataItemDefinitionDto;

    //ITEM_DICTIONARY
    public itemKeyDefinition?:IDataItemDefinitionDto;

    //ITEM_DICTIONARY, SELECT_LIST
    public dataItemList?:DataItemListModel; //a pointer to the dataProvider.dataItemListsDict entry

    //DATAPROVIDER_LINK
    public linkedDataProviderBodyDto?:IDataProviderBodyDto; //just the dto, no need to convert it into a model and load all dependencies when we only need the name (for now)

    //---------------------------------
    // Constructor
    //---------------------------------

    constructor(p_propertyDefinition:IPropertyDefinitionDto, p_data:any, p_dataProvider:DataProviderModel, p_parent:DataModel | null)
    {
        super(p_dataProvider, p_parent);
        this.propertyDefinition = p_propertyDefinition;
        this.isEditable = this.dataProvider.isEditable;  //no translation rights for now
        this.build(p_data);
        this.validate();
    }

    //---------------------------------
    // Public Methods
    //---------------------------------

    public build(p_data:any)
    {
        // console.log(this.propertyDefinition.identifier);
        //this.name =  languageManager.getTranslationForValue<string>(this.propertyDefinition.name as IMultiLangString, AppUserModel.getInstance().langCode);

        if (this.propertyDefinition.isPrimitive)
        {
            //in case of a new or "empty" saved prop
            if (p_data === undefined || p_data === null)
            {
                //if a default value is defined in the prop def
                if (this.propertyDefinition.defaultValue !== undefined && this.propertyDefinition.defaultValue !== null)
                {
                    if (this.propertyDefinition.isMultiLingual)
                    {
                        //check if it is a multilangdict as default value, because we need one for vue's observers
                        if (typeof this.propertyDefinition.defaultValue === "object")
                        {
                            //is a default value defined for the active language, if not set null
                            const transValue:any = this.propertyDefinition.defaultValue[this.dataProvider.activeLangCode] ? this.propertyDefinition.defaultValue[this.dataProvider.activeLangCode] : null; //otherwise it would be "undefined" instead of "null"
                            this._displayedValue = {[this.dataProvider.activeLangCode]: transValue};
                        }
                        else
                        {
                            //if it is a single default value for all languages
                            this._displayedValue = {[this.dataProvider.activeLangCode]: this.propertyDefinition.defaultValue};
                        }
                    }
                    else
                    {
                        this._displayedValue = this.propertyDefinition.defaultValue;
                    }
                }
                else
                { //no default values defined in the prop def
                    if (this.propertyDefinition.isMultiLingual)
                    { //but we need a dict for vue's observers if multlingual

                        this._displayedValue = {[this.dataProvider.activeLangCode]: null};
                    }else{
                        this._displayedValue = null;
                    }
                }
            }
            else  //there is some previous data, sa directly point to it...
            {
                this._displayedValue = p_data;
            }
            //...but make a copy, needed for multilingual dicts
            this.rawValue = DataUtil.deepCopy(this._displayedValue);
        }
        else
        {
            this.rawValue = DataUtil.deepCopy(p_data);

            if (this.rawValue === null && this.propertyDefinition.defaultValue) //it's a new (empty) property, so check if there are defaults provided
            {
                this.rawValue = DataUtil.deepCopy(this.propertyDefinition.defaultValue);
            }

            //needed before we make "containers"
            if (this.propertyDefinition.itemDefinitionURI)
            {
                const itemDefinition:IDataItemDefinitionDto | null = this.dataProvider.getItemDefinitionByURI(this.propertyDefinition.itemDefinitionURI);
                if (itemDefinition)
                {
                    this.itemDefinition = itemDefinition;
                }
                else
                {
                    console.log("couldn get itemDefinition for ", this.propertyDefinition.itemDefinitionURI);
                }
            }
            if (this.propertyDefinition.itemKeyDefinitionURI)
            {
                const itemKeyDefinition:IDataItemDefinitionDto | null = this.dataProvider.getItemDefinitionByURI(this.propertyDefinition.itemKeyDefinitionURI);
                if (itemKeyDefinition)
                {
                    this.itemKeyDefinition = itemKeyDefinition;
                }
                else
                {
                    console.log("couldn get itemKeyDefinition for ", this.propertyDefinition.itemKeyDefinitionURI);
                }
            }


            //create "containers" for non primitives
            if (this.propertyDefinition.type === PropertyType.SINGLE_ITEM)
            {
                this._displayedValue = new DataItemModel(this.itemDefinition!, this.rawValue, this.dataProvider, this);
            }
            else if (this.propertyDefinition.type === PropertyType.ITEM_LIST)
            {
                this._displayedValue = new DataItemListModel(this.itemDefinition!, this.rawValue, this.dataProvider, this);

            }
            else if (this.propertyDefinition.type === PropertyType.ITEM_DICTIONARY)
            {
                this._displayedValue = new DataItemDictionaryModel(this.itemKeyDefinition!, this.itemDefinition!, this.propertyDefinition, this.rawValue, this.dataProvider, this);
            }
            else if (this.propertyDefinition.type === PropertyType.SELECT_LIST)
            {
                this._displayedValue = new DataItemSelectListModel(this.itemDefinition!, this.propertyDefinition, this.rawValue, this.dataProvider, this);
            }
            else if (this.propertyDefinition.type === PropertyType.EXTERNAL_LIST)
            {
                this._displayedValue = new DataItemExternalListModel(this.propertyDefinition, this.rawValue, this.dataProvider, this);
            }
            else if (this.propertyDefinition.type === PropertyType.DATAPROVIDER_LINK)
            {
                this._displayedValue = new DataItemProviderLinkModel(this.propertyDefinition.dataSchemaURI!, this.rawValue, this.dataProvider, this);
            }
            else if (this.propertyDefinition.type === PropertyType.CONTENT_LINK)
            {
                this._displayedValue = new DataItemContentLinkModel(this.propertyDefinition.allowedContentTypes!, this.rawValue, this.dataProvider, this);
            }
        }



    }

    //return the raw data object
    public mapToDto(p_dtoType:DtoType):any
    {
        if (p_dtoType === DtoType.BODY)
        {
            if (this.propertyDefinition.isPrimitive)
            {
                if (this.propertyDefinition.type === PropertyType.NUMBER)
                {
                    return parseFloat(this.rawValue);
                }
                return this.rawValue;
            }
            else
            {
                return (this._displayedValue as DataModel).mapToDto(DtoType.BODY);
            }
        }
    }

    public getLogName():string
    {
        let logName:string = languageManager.getTranslationForValue<string>(this.propertyDefinition.name as IMultiLangString, AppUserModel.getInstance().langCode);
        if (this.propertyDefinition.isMultiLingual)
        {
            logName += ` (${this.dataProvider.activeLangCode})`;
        }
        return logName;
    }


    public validate()
    {
        let isValid:boolean = true;
        this.inValidType = null;
        this.inValidLanguages = [];

        //try to prove invalid
        if (this.propertyDefinition.isRequired || this.propertyDefinition.stringFormat)
        {
            if (this.propertyDefinition.isMultiLingual)
            {
                if (typeof this._displayedValue === "object")
                {
                    for (let langCode in this._displayedValue)
                    {
                        if (this._displayedValue.hasOwnProperty(langCode))
                        {
                            const inValidType = this._checkValue(this._displayedValue[langCode]);
                            if (inValidType)
                            {
                                this.inValidLanguages.push(langCode);
                                this.inValidType = inValidType;
                            }
                        }
                    }
                }
                else //safeguard, should normally never be possible
                {
                    this.inValidType = InValidType.REQUIRED_IN_AT_LEAST_ONE_LANGUAGE;
                }
            }
            else
            {
                this.inValidType = this._checkValue(this._displayedValue);
            }

            if (this.inValidType)
            {
                isValid = false;
            }
        }
        else
        {
            isValid = true;
        }
        isValid = isValid && this.invalidChildren.length === 0;

        if (isValid != this.isValid)
        {
            // console.log("VALIDITY CHANGED:", this.logModelPath(), this.inValidType);
            this.isValid = isValid;
            if (this.__parent)
            {
                this.__parent.childValidityChange(this.isValid, this);
            }
        }

    }

    private _checkValue(p_value:any):InValidType | null
    {
        if (this.propertyDefinition.type === PropertyType.TEXT)
        {
            if (p_value && this.propertyDefinition.stringFormat === StringDataFormat.EMAIL)
            {
                if (!Util.validateEmail(p_value))
                {
                    return InValidType.NEED_EMAIL_FORMAT;
                }
            }

            if (this.propertyDefinition.minLength)
            {
                if (!p_value || p_value.length < this.propertyDefinition.minLength)
                {
                    return InValidType.FILL_IN_MIN_CHARS;
                }
            }
            if (this.propertyDefinition.maxLength)
            {
                if (p_value && p_value.length > this.propertyDefinition.maxLength)
                {
                    return InValidType.FILL_IN_MAX_CHARS;
                }
            }
        }
        else if (this.propertyDefinition.type === PropertyType.NUMBER)
        {
            const nrValue = parseFloat(p_value);
            if (this.propertyDefinition.isRequired && (nrValue === null || nrValue === undefined || isNaN(nrValue)))
            {
                return InValidType.FILL_IN_NUMBER;
            }
        }
        else if (this.propertyDefinition.type === PropertyType.DATE)
        {
            if (this.propertyDefinition.isRequired && !p_value)
            {
                return InValidType.FILL_IN_DATE;
            }
        }
        else if (this.propertyDefinition.type === PropertyType.COLOR)
        {
            if (this.propertyDefinition.isRequired && !p_value)
            {
                return InValidType.SELECT_A_COLOR;
            }
        }
        else if (this.propertyDefinition.type === PropertyType.ASSET_FILE)
        {
            if (this.propertyDefinition.isRequired && !p_value)
            {
                return InValidType.CHOOSE_A_FILE;
            }
        }
        else if (this.propertyDefinition.type === PropertyType.FIXED_LIST)
        {
            if (this.propertyDefinition.isRequired && !p_value)
            {
                return InValidType.MAKE_A_SELECTION;
            }
        }
        else if (this.propertyDefinition.type === PropertyType.SELECT_LIST)
        {
            return (this._displayedValue as DataItemSelectListModel).checkValue();

        }
        else if (this.propertyDefinition.type === PropertyType.EXTERNAL_LIST)
        {
            return (this._displayedValue as DataItemExternalListModel).checkValue();

        }
        else if (this.propertyDefinition.type === PropertyType.ITEM_LIST)
        {
            const listModel:DataItemListModel = this._displayedValue as DataItemListModel;

            if (this.propertyDefinition.minItems && listModel.list.length < this.propertyDefinition.minItems)
            {
                return InValidType.CREATE_MIN_ITEMS;
            }
            if (this.propertyDefinition.maxItems && listModel.list.length > this.propertyDefinition.maxItems)
            {
                return InValidType.CREATE_MAX_ITEMS;
            }

        }
        else if (this.propertyDefinition.type === PropertyType.ITEM_DICTIONARY)
        {
            if (this.propertyDefinition.isRequired)
            {
                const dict:DataItemDictionaryModel = this._displayedValue as DataItemDictionaryModel;
                if (!dict.dataProviderURI)
                {
                    return InValidType.REQUIRED_DICTIONARY;
                }
            }

        }
        else if (this.propertyDefinition.type === PropertyType.DATAPROVIDER_LINK)
        {
            if (this.propertyDefinition.isRequired)
            {
                const linkModel:DataItemProviderLinkModel = this._displayedValue as DataItemProviderLinkModel;
                if (!linkModel.dataProviderURI)
                {
                    return InValidType.REQUIRED_DATAPROVIDER;
                }
            }

        } else if (this.propertyDefinition.type === PropertyType.CONTENT_LINK)
        {
            if (this.propertyDefinition.isRequired)
            {
                const linkModel:DataItemContentLinkModel = this._displayedValue as DataItemContentLinkModel;
                if (!linkModel.contentID)
                {
                    return InValidType.REQUIRED_CONTENT;
                }
            }

        }


        return null;
    }


    public clearValue()
    {
        this.build(undefined);
        this.validate();
    }




    public mapToRows(p_rows:any, p_columnPrefix:string, p_targetRowIndex:number)
    {

        const targetRow:any = p_rows[p_targetRowIndex];

        const columnIdentifier:string = p_columnPrefix + this.propertyDefinition.identifier;

        // console.log(columnIdentifier, p_targetRowIndex, targetRow, p_rows);

        //add it as a column to the last row
        if (this.propertyDefinition.isPrimitive)
        {
            //if the property is multilingual, add a column for each filled in language
            if (this.propertyDefinition.isMultiLingual)
            {
                if (typeof this._displayedValue === "object")
                {
                    for (let langCode in this._displayedValue)
                    {
                        if (this._displayedValue.hasOwnProperty(langCode))
                        {
                            const cellValue:any = this._convertToCellValue(this._displayedValue[langCode]);
                            if (cellValue !== null)
                            {
                                targetRow[`${columnIdentifier}(${langCode})`] = cellValue;
                            }

                        }
                    }
                }
            }
            else
            {
                const cellValue:any = this._convertToCellValue(this.rawValue);
                if (cellValue !== null)
                {
                    targetRow[columnIdentifier] = cellValue;
                }
            }

        }
        else
        {

            let columnIdentifier:string = p_columnPrefix + this.propertyDefinition.identifier;
            if (this.propertyDefinition.type !== PropertyType.SELECT_LIST && this.propertyDefinition.type !== PropertyType.EXTERNAL_LIST && this.propertyDefinition.type !== PropertyType.CONTENT_LINK && this.propertyDefinition.type !== PropertyType.DATAPROVIDER_LINK && this.propertyDefinition.type !== PropertyType.ITEM_DICTIONARY)
            {
                columnIdentifier += "-";
            }

            if (this.propertyDefinition.type === PropertyType.SINGLE_ITEM)
            {
                (this._displayedValue as DataItemModel).mapToRows(p_rows, columnIdentifier, p_targetRowIndex);
            }
            else if (this.propertyDefinition.type === PropertyType.ITEM_LIST)
            {
                (this._displayedValue as DataItemListModel).mapToRows(p_rows, columnIdentifier, p_targetRowIndex);
            }
            else if (this.propertyDefinition.type === PropertyType.ITEM_DICTIONARY)
            {
                (this._displayedValue as DataItemDictionaryModel).mapToRows(p_rows, columnIdentifier, p_targetRowIndex);
            }
            else if (this.propertyDefinition.type === PropertyType.SELECT_LIST)
            {
                (this._displayedValue as DataItemSelectListModel).mapToRows(targetRow, columnIdentifier);
            }
            else if (this.propertyDefinition.type === PropertyType.EXTERNAL_LIST)
            {
                (this._displayedValue as DataItemExternalListModel).mapToRows(targetRow, columnIdentifier);
            }
            else if (this.propertyDefinition.type === PropertyType.DATAPROVIDER_LINK)
            {
                (this._displayedValue as DataItemProviderLinkModel).mapToRows(targetRow, columnIdentifier);
            }
            else if (this.propertyDefinition.type === PropertyType.CONTENT_LINK)
            {
                (this._displayedValue as DataItemContentLinkModel).mapToRows(targetRow, columnIdentifier);
            }

        }
    }

    public getNeededRows():number
    {
        let neededRows:number = 1;

        //if it is a type that (possibly) needs more than 1 row, ask them

        if (this.propertyDefinition.type === PropertyType.ITEM_LIST)
        {
            neededRows = Math.max(neededRows, (this._displayedValue as DataItemListModel).getNeededRows());
        }
        else if (this.propertyDefinition.type === PropertyType.SINGLE_ITEM)
        {
            neededRows = Math.max(neededRows, (this._displayedValue as DataItemModel).getNeededRows());
        }
        else if (this.propertyDefinition.type === PropertyType.ITEM_DICTIONARY)
        {
            neededRows = Math.max(neededRows, (this._displayedValue as DataItemDictionaryModel).getNeededRows());
        }
        return neededRows;
    }



    private _convertToCellValue(p_value:any)
    {
        if (p_value === null || p_value === undefined)
        {
            return null;
        }

        if (this.propertyDefinition.type === PropertyType.NUMBER)
        {
            return parseFloat(p_value);
        }
        return p_value;
    }



    public static mapFromRows(p_dataProvider:DataProviderModel, p_propDefinition:IPropertyDefinitionDto, p_rows:any[], p_columns:IXlsColumn[], p_level:number, p_columnPrefix:string, p_result:IXlsImportResult)
    {
        const thisLevelColumns:IXlsColumn[] = p_columns.filter(p_column => p_column.level === p_level); //only look at the columns on this level

        let thisPropColumns:IXlsColumn[] = thisLevelColumns.filter(p_column => p_column.propertyDefinition === p_propDefinition); //some properties have multiple columns...

        if (p_level > 0) //if its a deeper level (one with a property), than the property identifier (p_columnPrefix) is needed as differentiator
        {
            thisPropColumns = thisPropColumns.filter(p_targetColumn => p_targetColumn.identifier.indexOf(p_columnPrefix) === 0);
        }

        const thisPropColumn:IXlsColumn | undefined = thisPropColumns[0]; //...most only have one

        let value:any;

        if (p_propDefinition.isPrimitive)
        {
            //if the property is multilingual
            if (p_propDefinition.isMultiLingual)
            {
                value = {};
                for (let i = 0; i < thisPropColumns.length; i++)
                {
                    const thisMlPropColumn = thisPropColumns[i];
                    const langCode:string = thisMlPropColumn.identifier.substring(thisMlPropColumn.identifier.indexOf("(") + 1, thisMlPropColumn.identifier.indexOf(")")); //parse the langcode out
                    const cellValue:any = p_rows[0][thisMlPropColumn.identifier]; //a primitive prop should be found on the first row
                    if (cellValue !== undefined && cellValue !== null)
                    {
                        value[langCode] = PropertyModel._convertFromCellValue(p_propDefinition, cellValue);
                    }
                }
            }
            else
            {
                if (thisPropColumn)
                {
                    const cellValue:any = p_rows[0][thisPropColumn.identifier]; //a primitive prop should be found on the first row
                    return PropertyModel._convertFromCellValue(p_propDefinition, cellValue);
                }
            }
        }
        else
        {
            //we go a level deeper
            value = p_propDefinition.type;
            const columnPrefix = p_columnPrefix + p_propDefinition.identifier + "-";

            let itemDefinition:IDataItemDefinitionDto | null = null;
            if (p_propDefinition.itemDefinitionURI)
            {
                itemDefinition = p_dataProvider.getItemDefinitionByURI(p_propDefinition.itemDefinitionURI!);
            }


            if (p_propDefinition.type === PropertyType.SINGLE_ITEM)
            {
                return DataItemModel.mapFromRows(p_dataProvider, itemDefinition!, p_rows, p_columns, p_level + 1, columnPrefix, p_result) //todo
            }
            else if (p_propDefinition.type === PropertyType.ITEM_LIST)
            {
                // console.log(p_propDefinition!.identifier,  "going to level", p_level +1);
                return DataItemListModel.mapFromRows(p_dataProvider, itemDefinition!, p_rows, p_columns, p_level + 1, columnPrefix, p_result);
            }
            else if (p_propDefinition.type === PropertyType.ITEM_DICTIONARY)
            {
                // const itemKeyDefinition:IDataItemDefinitionDto | null = p_dataProvider.getItemDefinitionByURI(p_propDefinition.itemKeyDefinitionURI!);
                // console.log(itemKeyDefinition, itemDefinition);
                // this._displayedValue = new DataItemDictionaryModel(this.itemKeyDefinition!, this.itemDefinition!, this.rawValue, this.dataProvider, this);
                return DataItemDictionaryModel.mapFromRows(p_dataProvider, itemDefinition!, p_rows, thisPropColumn, p_columns, p_level + 1, columnPrefix, p_result);

            }
            else if (p_propDefinition.type === PropertyType.SELECT_LIST)
            {
                return DataItemSelectListModel.mapFromRow(p_propDefinition, p_rows[0], thisPropColumns, p_result)
            }
            else if (p_propDefinition.type === PropertyType.EXTERNAL_LIST)
            {
                return DataItemExternalListModel.mapFromRow(p_propDefinition, p_rows[0], thisPropColumns, p_result)
            }
            else if (p_propDefinition.type === PropertyType.DATAPROVIDER_LINK)
            {
                return DataItemProviderLinkModel.mapFromRow(p_propDefinition, p_rows[0], thisPropColumns, p_result)
            }
            else if (p_propDefinition.type === PropertyType.CONTENT_LINK)
            {
                return DataItemContentLinkModel.mapFromRow(p_propDefinition, p_rows[0], thisPropColumns, p_result)
            }

        }


        //return "prim value"
        //set _id



        // console.log(p_rows);
        return value;

    }


    private static _convertFromCellValue(p_propDefinition:IPropertyDefinitionDto, p_value:any)
    {
        if (p_value === null || p_value === undefined)
        {
            return null;
        }

        if (p_propDefinition.type === PropertyType.NUMBER)
        {
            return parseFloat(p_value);
        }
        return p_value;
    }


}
